export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        examination :'',
        examinations : '',
    },

    
    getters : {
        examination: (state )=> state.examination,
        examinations: (state )=> state.examinations,
    },

    mutations : {
        setexamination: (state, examination) => (state.examination = examination),
        setexaminations :(state,examinations ) => (state.examinations = examinations), 
    },


    actions : {


        async do_assessment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'do_assessment' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/examinations/do_assessment/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setexamination',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async get_examination( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_examination' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/examinations/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                commit('setpageloader', '')
                if(response.data.res){
                    commit('setexamination',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                commit('setpageloader', '')
                reject(error)
            });
        });
            
                
        },



        async get_examination_history( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_examination_history')
            return new Promise((resolve, reject) => {
            axios.post("user/examinations/history?page=" + page,  data)
            .then(response => {	
                commit('setloader', false)
                commit('setpageloader', '')
                if(response.data.res){
                    commit('setexaminations',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                commit('setpageloader', '')
                reject(error)
            });
        });
            
                
        },




        async change_examination_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_examination_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/examinations/status/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setexamination',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },

        async update_question_type( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_question_type')
            return new Promise((resolve, reject) => {
            axios.post("user/examinations/itemtype/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setexamination',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async update_question_user_answer( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_question_user_answer' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/examinations/itemanswer/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setexamination',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },

        async submit_question_user_answer( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'submit_question_user_answer' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/examinations/submitanswer/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setexamination',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async mark_examination_answers( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'mark_examination_answers' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/examinations/mark/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setexamination',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },
    },


}