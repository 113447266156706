export const namespaced = true 
import axios from 'axios'
// import router from '@/router'
//import store from '@/store'

export default {
    state : {
        assessment :'',
        assessments : '',
    },

    
    getters : {
        assessment: (state )=> state.assessment,
        assessments: (state )=> state.assessments,
    },

    mutations : {
        setassessment: (state, assessment) => (state.assessment = assessment),
        setassessments :(state,assessments ) => (state.assessments = assessments), 
    },


    actions : {

        async create_assessment( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_assessment')
            return new Promise((resolve, reject) => {
            axios.post("admin/assessments/create",  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setassessments',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async get_assessments( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_assessments')
            return new Promise((resolve, reject) => {
            axios.post("admin/assessments?page=" + page,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setassessments',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async assessment_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_assessment' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/assessments/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setassessment',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },



        async update_assessment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_assessment' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/assessments/update/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setassessments',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },

        
        async change_assessment_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_assessment_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/assessments/status/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setassessments',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async delete_assessment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_assessment' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/assessments/delete/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setassessments',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


      

        ////users


        async get_subjectassessments( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subjectassessments')
            console.log(data)
            return new Promise((resolve, reject) => {
            axios.post("user/assessments?page=" + page,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setassessments',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },
},


}