export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        section :'',
        sections : '',
    },

    
    getters : {
        section: (state )=> state.section,
        sections: (state )=> state.sections,
    },

    mutations : {
        setsection: (state, section) => (state.section = section),
        setsections :(state,sections ) => (state.sections = sections), 
    },


    actions : {

        async create_section( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_section')
            return new Promise((resolve, reject) => {
            axios.post("admin/sections/create",  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setsections',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async get_sections( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_sections')
            return new Promise((resolve, reject) => {
            axios.post("admin/sections?page=" + page,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setsections',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async get_section( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_section' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/sections/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setsection',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },



        async update_section( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_section' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/sections/update/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setsections',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },

        
        async change_section_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_section_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/sections/status/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setsections',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async delete_section( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_section' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/sections/delete/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setsections',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },
},


}