export const namespaced = true 
import axios from 'axios'
import offlinecirriculm from '@/store/modules/offlinedata/offlinecirriculm.js'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        cirriculum :'',
        cirricula : '',
    },

    
    getters : {
        cirriculum: (state )=> state.cirriculum,
        cirricula: (state )=> state.cirricula,
    },

    mutations : {
        setcirriculum: (state, cirriculum) => (state.cirriculum = cirriculum),
        setcirricula :(state,cirricula ) => (state.cirricula = cirricula), 
    },


    actions : {

        async create_curriculum( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_curriculum')
            return new Promise((resolve, reject) => {
            axios.post("admin/curriculum/create",  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setcirricula',response.data.res)
                    const offlinedata =  offlinecirriculm.addCurriculum(response.data.res)
                   
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                const offlinedata = offlinecirriculm.getCurriculum()
               
                commit('setcirricula', offlinedata)
                reject(error)
            });
        });
            
                
        },


        async get_curricula( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_curricula')
            return new Promise((resolve, reject) => {
            axios.post("admin/curriculum?page=" + page,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setcirricula',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },



        async update_curriculum( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_curriculum' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/curriculum/update/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setcirricula',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },

        
        async change_curriculum_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_curriculum_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/curriculum/status/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setcirricula',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async delete_curriculum( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_curriculum' + id)
            return new Promise((resolve, reject) => {
            axios.post("admin/curriculum/delete/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setcirricula',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },
},


}