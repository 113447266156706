export const namespaced = true 
import axios from 'axios'
import router from '@/router'
import { useToast } from 'vue-toastification'
const toast = useToast()
//import store from '@/store'

export default {
    state : {
        authuser :'',
        authlocation : '',
        isAuth:'',
        isVerified:'',
		collectionnum:'',
		returnvalue:'',
		teacherprofile:''
    },
    
    getters : {
        authuser: (state )=> state.authuser,
        authlocation: (state )=> state.authlocation,
		teacherprofile: (state )=> state.teacherprofile,
		collectionnum: (state )=> state.collectionnum,
		returnvalue: (state )=> state.returnvalue,
        isAuth: state => !!state.authuser,
    },


actions : {

    async loginuser( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'loginuser')
        return new Promise((resolve, reject) => {
		axios.post("auth/login",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('current_system_user_auth_token' + response.data.res.id, response.data.authtoken.accessToken)
                localStorage.setItem('system_loggedin_user' + response.data.res.id, response.data.res.id)
				commit('setauthuser', response.data.res)
				router.push({ name: 'welcome' });
			}else{
                router.push({ name: 'login' });
            }
			resolve(response)
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
						toast.success(error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject(error)
		});
    });
		
			
	},


	async registerasanonymoususer( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'registerasanonymoususer')
        return new Promise((resolve, reject) => {
		axios.post("auth/registerasanonymoususer",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('current_system_user_auth_token' + response.data.res.id, response.data.authtoken.accessToken)
                localStorage.setItem('system_loggedin_user' + response.data.res.id, response.data.res.id)
				commit('setauthuser', response.data.res)
				router.push({ name: 'welcome' });
			}else{
                router.push({ name: 'login' });
            }
			resolve(response)
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
						toast.success(error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject(error)
		});
    });
		
			
	},

    async registeruser( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'registeruser')
        return new Promise((resolve, reject) => {
		axios.post("auth/register",  data)
		.then(response => {	
			commit('setloader', false)
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('current_system_user_auth_token' + response.data.res.id, response.data.authtoken.accessToken)
                localStorage.setItem('system_loggedin_user', response.data.res.id)
				commit('setauthuser', response.data.res)
				router.push({ name: 'welcome' });
			}else{
                router.push({ name: 'login' });
            }
			resolve(response)
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
		
		
			
	},

	async updateprofile( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'updateaccount')
        return new Promise((resolve, reject) => {
		axios.post("auth/updateaccount",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('current_system_user_auth_token' + response.data.res.id, response.data.authtoken.accessToken)
                commit('system_loggedin_user', response.data.res.id)
				commit('setauthuser', response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},

	async updatepassword( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'updatepassword')
        return new Promise((resolve, reject) => {
		axios.post("auth/updatepassword",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('current_system_user_auth_token' + response.data.res.id, response.data.authtoken.accessToken)
                commit('system_loggedin_user', response.data.res.id)
				commit('setauthuser', response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });		
	},



	async updateprofilepic( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'updateimage')
        return new Promise((resolve, reject) => {
		axios.post("auth/updateprofilepic",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				localStorage.setItem('current_system_user_auth_token' + response.data.res.id, response.data.authtoken.accessToken)
                commit('system_loggedin_user', response.data.res.id)
				commit('setauthuser', response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},


	async logoutuser( { commit }) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'logout')
        return new Promise((resolve, reject) => {
		axios.get("auth/logout")
		.then(response => {	
			commit('setloader', false)
			if(response.data.msg){
			    commit('setmsg', response.data.msg)   
			}
			window.localStorage.clear();
			localStorage.clear();
			commit('setauthuser','')
			router.push({ name: 'login' });	
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
					window.localStorage.clear();
					localStorage.clear();
					commit('setauthuser','')
					router.push({ name: 'login' });	
				}	
			}
			reject()
		});
    });	
			
	},


	async getteacherprofile( { commit }) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'getteacherprofile')
        return new Promise((resolve, reject) => {
		axios.get("auth/teacherprofile")
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
			commit('setteacherprofile', response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},




	async requestmembership( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'requestmembership')
        return new Promise((resolve, reject) => {
		axios.post("auth/requestmembership", data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
			}
			resolve(response)
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('setmsg', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject(response)
		});
    });	
			
	},





},

mutations : {
	setToken: (state, token) => (state.token = token),
	setauthuser :(state,authuser ) => (state.authuser = authuser), 
	setauthlocation :(state,authlocation ) => (state.authlocation = authlocation), 
	setisAuth :(state,isAuth ) => (state.isAuth = isAuth), 
	setisVerified :(state,isVerified ) => (state.isVerified = isVerified),  
	setcollectionnum :(state,collectionnum ) => (state.collectionnum = collectionnum),
	setreturnvalue :(state,returnvalue ) => (state.returnvalue = returnvalue),
	setteacherprofile :(state,teacherprofile ) => (state.teacherprofile = teacherprofile),
},
}