import store from '@/store'

const adminguard = (to, from, next) => {
    let data ={
        token: localStorage.getItem('current_system_user_auth_token' + store.getters.authuser.id),
        user :store.getters.authuser,
        id: localStorage.getItem('system_loggedin_user' + store.getters.authuser.id),
        realid:store.getters.authuser.id
    }
    console.log(data)
    if(localStorage.getItem('current_system_user_auth_token' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id == localStorage.getItem('system_loggedin_user' + store.getters.authuser.id)){
        if(store.getters.authuser.role == 'admin'){
            return next()
        }else{
            return next({ name: 'welcome' })
        }
          
    }else{
      return next({ name: 'login' })
    }
  };

let routes = [
    { 
        path: "/admin",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/admin/app_dash.vue"),
        
        children: [

            {
                path: "",
                props:true,
                name:'adminwelcome',
                component: () => import("@/views/admin/main/home.vue"),
                meta:{
                    title:'Welcome Admin'
                }
            },




            {
                path: "curricula",
                props:true,
                name:'adminmanagecurricula',
                component: () => import("@/views/admin/curricula/managecurricula.vue"),
                meta:{
                    title:'Manage Curricula'
                }
            },

            
            {
                path: "subjects",
                props:true,
                name:'adminmanagesubjects',
                component: () => import("@/views/admin/subjects/managesubjects.vue"),
                meta:{
                    title:'Manage Subjects'
                }
            },



            {
                path: "classes",
                props:true,
                name:'adminmanageclasses',
                component: () => import("@/views/admin/classes/manageclasses.vue"),
                meta:{
                    title:'Manage Classes'
                }
            },

            {
                path: "topics",
                props:true,
                name:'adminmanagetopics',
                component: () => import("@/views/admin/topics/managetopics.vue"),
                meta:{
                    title:'Manage Topics'
                }
            },


            {
                path: "assessments",
                props:true,
                component: () => import("@/views/admin/assesments/main.vue"),
                children:[

                    {
                        path: "assessments",
                        props:true,
                        name:'adminmanageassesments',
                        component: () => import("@/views/admin/assesments/assesments.vue"),
                        meta:{
                            title:'Manage Assessments'
                        }
                    },


                    {
                        path: ":id",
                        props:true,
                        name:'adminassessmentdetails',
                        component: () => import("@/views/admin/assesments/assessmentdetails.vue"),
                        meta:{
                            title:'Manage Assessment Details'
                        }
                    },

                ]
            },



            {
                path: "libraries",
                props:true,
                component: () => import("@/views/admin/libraries/main.vue"),
                children:[

                    {
                        path: "libraries",
                        props:true,
                        name:'adminmanagelibraries',
                        component: () => import("@/views/admin/libraries/libraries.vue"),
                        meta:{
                            title:'Manage Libraries'
                        }
                    },


                    {
                        path: "libraries/:id",
                        props:true,
                        name:'adminlibrarydetails',
                        component: () => import("@/views/admin/libraries/librarydetails.vue"),
                        meta:{
                            title:'Manage Library Details'
                        }
                    },

                ]
            },

        ]
    }
]

export default routes;