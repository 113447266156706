import { openDB } from 'idb'


const dbName = 'smartschoolkit_db';
const storeName = 'curricula_data';

const openDatabase = async () => {
    const db = await openDB(dbName, 3, {
      upgrade(db) {
        console.log('Upgrading database');
        if (!db.objectStoreNames.contains(storeName)) {
          console.log('Creating object store');
          db.createObjectStore(storeName, { keyPath: 'id' });
        }
      }
    });
    
    if (!db.objectStoreNames.contains(storeName)) {
      console.log('Creating object store');
      db.close();
      const newDb = await openDB(dbName, 4, {
        upgrade(db) {
          db.createObjectStore(storeName, { keyPath: 'id' });
        }
      });
      return newDb;
    }
    
    return db;
  }
  
  const addCurriculum = async (data) => {
    const db = await openDatabase()
    const tx = db.transaction(storeName, 'readwrite')
    const store = tx.objectStore(storeName)
    const savedata = await Promise.all(data.data.map(item => store.put(item)))
    await tx.done
  }
  
  const getCurriculum = async (page, limit) => {
    const db = await openDatabase()
    const tx = db.transaction(storeName, 'readonly')
    const store = tx.objectStore(storeName)
    const allData = await store.getAll()
    const startIndex = (page - 1) * limit
    const endIndex = startIndex + limit
    const paginatedData = allData.slice(startIndex, endIndex)
    await tx.done
    return paginatedData
  }
  
  const deleteCurriculum = async () => {
    const db = await openDatabase()
    const tx = db.transaction(storeName, 'readwrite')
    const store = tx.objectStore(storeName)
    await store.clear()
    await tx.done
  }

// Export the database functions for use in other modules
export default {
  openDatabase,
  addCurriculum,
  getCurriculum,
  deleteCurriculum
}