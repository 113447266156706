export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        lesson :'',
        lessons : '',
    },

    
    getters : {
        lesson: (state )=> state.lesson,
        lessons: (state )=> state.lessons,
    },

    mutations : {
        setlesson: (state, lesson) => (state.lesson = lesson),
        setlessons :(state,lessons ) => (state.lessons = lessons), 
    },


    actions : {


        async start_lesson( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'start_lesson' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/lessons/start_lesson/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setlesson',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },


        async get_lesson( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lesson' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/lessons/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                commit('setpageloader', '')
                if(response.data.res){
                    commit('setlesson',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                commit('setpageloader', '')
                reject(error)
            });
        });
            
                
        },


        async delete_lesson( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_lesson' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/lessons/delete/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                commit('setpageloader', '')
                if(response.data.res){
                    commit('setlessons',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                commit('setpageloader', '')
                reject(error)
            });
        });
            
                
        },

        async get_lesson_history( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lesson_history')
            return new Promise((resolve, reject) => {
            axios.post("user/lessons/history?page=" + page,  data)
            .then(response => {	
                commit('setloader', false)
                commit('setpageloader', '')
                if(response.data.res){
                    commit('setlessons',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                commit('setpageloader', '')
                reject(error)
            });
        });
            
                
        },




        async change_lesson_status( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_lesson_status' + id)
            return new Promise((resolve, reject) => {
            axios.post("user/lessons/status/" + id,  data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setlesson',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
        });
            
                
        },
        
        async get_usertopic_assessments( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_usertopic_assessments')
            return new Promise((resolve, reject) => {
            axios.post("user/lessons/assessments?page=" + page,  data)
            .then(response => {	
                commit('setloader', false)
                commit('setpageloader', '')
                if(response.data.res){
                    commit('setassessments',response.data.res)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterror', error.response.data.message)
                        }
                        
                    }
                }
                commit('setpageloader', '')
                reject(error)
            });
        });
            
                
        },


    },


}