import store from '@/store'
const authguard = (to, from, next) => {
    if(localStorage.getItem('system_loggedin_user' + store.getters.authuser.id) == null){
          return next()
    }else{
        return next({ name: 'welcome' })
    }
  };
let routes = [


    {
        path: "/auth",
        props:true,
        beforeEnter: authguard,
        component: () => import("@/views/auth/main.vue"),
        children:[

            {
                path: "login",
                props:true,
                name:'login',
                component: () => import("@/views/auth/login.vue"),
                meta:{
                    title:'Login to System'
                }
            },

            {
                path: "register",
                props:true,
                name:'register',
                component: () => import("@/views/auth/register.vue"),
                meta:{
                    title:'Register to System'
                }
            },

        ]
    },

]

export default routes;