export const namespaced = true 
export default {
    state : {
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloader:'',
        section_id:''
    },
    
    getters : {
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloader: (state )=> state.pageloader,
        section_id: (state )=> state.section_id,
    },

    actions : {

        async clearutils( { commit }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', '')
            commit('setapierror', '')
            commit('seterrors', '')
            commit('setpageloader', '')
        },



        async set_section_for_user( { commit }, data) {
            commit('setsection_id', data.section_id)
        }




    },

    mutations : { 
        setloader :(state,loading ) => (state.loading = loading), 
        setpageloader :(state,pageloader ) => (state.pageloader = pageloader), 
        seterror :(state,error ) => (state.error = error), 
        seterrors :(state,errors ) => (state.errors = errors), 
        setmsg :(state,msg ) => (state.msg = msg), 
        setapierror :(state,apierror ) => (state.apierror = apierror),
        setsection_id :(state,section_id ) => (state.section_id = section_id),
    },
}