import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const ls = new SecureLs({isCompression:false});

import utils  from './modules/utils'
import auth  from './modules/auth'

import cirriculm  from './modules/cirriculm.js'
import subjects  from './modules/subjects.js'
import classes  from './modules/classes.js'
import topics  from './modules/topics.js'
import assesment  from './modules/assesment.js'
import assessment_items  from './modules/assessment_items.js'
import libraries  from './modules/libraries.js'
import library_items  from './modules/library_items.js'
import examinations  from './modules/examinations.js'

import lessons  from './modules/lessons.js'
export default createStore({
  modules: {
    utils,
    auth,
    cirriculm,
    subjects,
    classes,
    topics,
    assesment,
    assessment_items,
    libraries,
    library_items,
    examinations,
    lessons,


  },
    
  plugins: [createPersistedState(
    {
      key : 'www.cookie_key.ntungamohighschool.org',
      paths: ['utils','auth'],
      storage:{
         getItem : (key) => ls.get(key),
         setItem: (key, value) => ls.set(key, value),
         removeItem: (key) => ls.remove(key)
      }
    }
  )],
  
})