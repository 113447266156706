import store from '@/store'

const userguard = (to, from, next) => {
    let data ={
        token: localStorage.getItem('current_system_user_auth_token' + store.getters.authuser.id),
        user :store.getters.authuser,
        id: localStorage.getItem('system_loggedin_user' + store.getters.authuser.id),
        realid:store.getters.authuser.id
    }
    console.log(data)
    if(localStorage.getItem('current_system_user_auth_token' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id == localStorage.getItem('system_loggedin_user' + store.getters.authuser.id)){
        if(store.getters.authuser.role == 'user'){
            return next()
        }else{
            return next({ name: 'adminwelcome' })
        }
    }else{
      return next({ name: 'login' })
    }
  };

let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: userguard,
        component: () => import("@/views/user/app_dash.vue"),
        
        children: [

            {
                path: "/",
                props:true,
                name:'welcome',
                component: () => import("@/views/user/main/home.vue"),
                meta:{
                    title:'Welcome '
                }
            },

            //history
            {
                path: "assessments/history",
                props:true,
                name:'userassessmentshistory',
                component: () => import("@/views/user/account/assessment_history.vue"),
                meta:{
                    title:'My Assessments History'
                }
            },


            {
                path: "libraries/history",
                props:true,
                name:'userlibraryhistory',
                component: () => import("@/views/user/account/library_history.vue"),
                meta:{
                    title:'My Study History'
                }
            },


            //assessments
            {
                path: "assessments/:id",
                props:true,
                name:'userassessments',
                component: () => import("@/views/user/assessments/assessments.vue"),
                meta:{
                    title:'My Assessments '
                }
            },

            {
                path: "examinations/:id",
                props:true,
                name:'examinationsdetails',
                component: () => import("@/views/user/assessments/examinationsdetails.vue"),
                meta:{
                    title:'My Assessments '
                }
            },

            {
                path: "assessments/bytopic-chapter",
                props:true,
                name:'bytopicchapter',
                component: () => import("@/views/user/assessments/topicassessments.vue"),
                meta:{
                    title:'Attempt Some Assessments'
                }
            },


            //libraries
            {
                path: "libraries/:id",
                props:true,
                name:'userlibraries',
                component: () => import("@/views/user/libraries/libraries.vue"),
                meta:{
                    title:'My Libraries '
                }
            },

             {
                path: "study/:id",
                props:true,
                name:'studydetails',
                component: () => import("@/views/user/libraries/librarydetails.vue"),
                meta:{
                    title:'My Libraries '
                }
            },

            //videos
            {
                path: "videos/:id",
                props:true,
                name:'uservideos',
                component: () => import("@/views/user/videos/videos.vue"),
                meta:{
                    title:'My videos '
                }
            },

            {
                path: "videos/:id",
                props:true,
                name:'videosdetails',
                component: () => import("@/views/user/videos/videodetails.vue"),
                meta:{
                    title:'My videos '
                }
            },

        ]
    }
]

export default routes;